import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const GoogleAdSense = ({ path }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (e) {
      console.error("Adsense error", e)
    }
  }, [path])

  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6737096029620039"
          crossOrigin="anonymous"
        ></script>
        <meta name="google-adsense-account" content="ca-pub-6737096029620039"></meta>
      </Helmet>
      <ins
        className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-6737096029620039"
        data-ad-slot="5489196409"
      ></ins>
    </>
  )
}

export default GoogleAdSense
