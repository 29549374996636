import React, { useState, useEffect } from 'react';

const AdBlockDetector = ({ children }) => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    const fakeAd = document.createElement('div');
    fakeAd.className = "textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox";
    fakeAd.style.height = '1px';
    document.body.appendChild(fakeAd);

    const adBlockDetected = fakeAd.offsetHeight === 0;
    setAdBlockDetected(adBlockDetected);

    document.body.removeChild(fakeAd);
  }, []);

  if (adBlockDetected) {
    return <div>AdBlocker detected. Content cannot be displayed.</div>;
  }

  return <>{children}</>;
};

export default AdBlockDetector;
